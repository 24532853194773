import { Auth, getAuth } from 'firebase/auth';
import { ConfigProvider, message } from 'antd';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import {
  getDataConsentScreenShown,
  setDataConsentScreenShown,
  setProfiles,
  setUser,
} from 'app/utils/localStorageHandler/userProfile';
import { getMessaging, onMessage } from 'firebase/messaging';
import { lazy, useEffect } from 'react';

import { Auth0WithNavigate } from './app/components/auth/Auth0Provider';
import CookieBanner from 'app/pages/Shared/CookieBanner';
import { FlagsmithProvider } from 'flagsmith/react';
import GetInTouch from 'app/pages/LandingPage/GetInTouch';
import { RecoilRoot } from 'recoil';
import RoleProtectedRoute from 'app/components/auth/AgentProtectedRoute';
import { ToastContainer } from 'react-toastify';
import { UserService } from 'app/services/user';
import environment from 'app/config/environment';
import flagsmith from 'flagsmith';
import { initializeClarity } from 'app/utils/clarity';
import { initializeFCM } from 'app/utils/fcm';
import { initializePendo } from 'app/utils/pendo';
import useSuspense from 'app/hooks/UseSuspense';
import { GlobalProvider } from 'app/Context/GlobalContext';

// Lazy loading components
const AgentDashboard = lazy(() => import('app/pages/Agent/Dashboard'));
const ComplianceDashboard = lazy(
  () => import('app/pages/Compliance/Dashboard')
);
const OfficeAdminDashboard = lazy(() => import('app/pages/OfficeAdmin'));
const AgentLoginPage = lazy(
  () => import('./app/pages/Agent/Login/AgentLoginPage')
);
const EntityRedirect = lazy(
  () => import('./app/components/common/EntityRedirect')
);
const ComplianceLoginPage = lazy(() => import('app/pages/Compliance/Login'));
const LandingPage = lazy(() => import('app/pages/LandingPage'));
const NotFound404Page = lazy(() => import('./app/pages/Errors/NotFound'));
const PrivacyPolicyPage = lazy(() => import('app/pages/Shared/PrivacyPolicy'));

let firebaseAuth: Auth;
const theme = createTheme({
  palette: {
    primary: {
      main: '#2F78EB',
    },
  },
});

function App() {
  // Disable console logs in production for non-staging environments
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_STAGE !== 'true'
  ) {
    console.log = () => {};
  }

  // Initialize Firebase Auth
  const firebaseConfig = {
    apiKey: environment.firebase.FIREBASE_API_KEY,
    authDomain: environment.firebase.FIREBASE_AUTH_DOMAIN,
    projectId: environment.firebase.FIREBASE_PROJECT_ID,
    storageBucket: environment.firebase.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: environment.firebase.FIREBASE_MESSAGING_SENDER_ID,
    appId: environment.firebase.FIREBASE_APP_ID,
    measurementId: environment.firebase.FIREBASE_MEASUREMENT_ID,
    vapidKey: environment.firebase.FIREBASE_VAPID_KEY,
  };

  let firebaseApp: FirebaseApp;
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
  } else {
    firebaseApp = getApps()[0];
  }

  firebaseAuth = getAuth(firebaseApp);
  const userID = localStorage.getItem('userId') || '';
  const partnerID = JSON.parse(localStorage.getItem('partnerId') || '{}');

  // Initialize Firebase Cloud Messaging and get a reference to the service
  useEffect(() => {
    // Initialize FCM only in production and if enabled using the feature flag
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_FCM_ENABLED === 'true'
    ) {
      try {
        initializeFCM(firebaseApp);

        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);

          const notificationTitle = payload.notification?.title || 'MaxHome';
          const notificationOptions = {
            body: payload.notification?.body,
            // icon: payload.notification?.icon,
          };

          if (Notification.permission === 'granted') {
            new Notification(notificationTitle, notificationOptions);
          }
          // Customize notification here
          message.info(
            `${payload.notification?.title}: ${payload.notification?.body}`
          );
        });
      } catch (error) {
        console.error('Error initializing FCM: ', error);
      }
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_FONTS_ENABLED === 'true') {
      document.body.classList.add('custom-fonts-enabled');
    }
  }, []);

  // List of routes
  const routes = [
    {
      path: `/redirect/*`,
      element: <EntityRedirect />,
    },
    {
      path: `/transaction`,
      element: <EntityRedirect />,
    },
    {
      path: `/get-in-touch`,
      element: <GetInTouch />,
    },
    {
      path: `/agent/login`,
      element: useSuspense(<AgentLoginPage />),
    },
    {
      path: `/compliance/login`,
      element: useSuspense(<ComplianceLoginPage />),
    },
    {
      path: `/agent/dashboard/*`,
      element: <RoleProtectedRoute Component={AgentDashboard} Role="agent" />,
    },
    {
      path: `/compliance/dashboard/*`,
      element: (
        <RoleProtectedRoute Component={ComplianceDashboard} Role="compliance" />
      ),
    },
    {
      path: `/office-admin/dashboard/*`,
      element: (
        <RoleProtectedRoute
          Component={OfficeAdminDashboard}
          Role="officeAdmin"
        />
      ),
    },
    {
      path: '',
      element: useSuspense(<LandingPage />),
    },
    {
      path: `/privacy-policy`,
      element: useSuspense(<PrivacyPolicyPage />),
    },
    {
      path: `/*`,
      element: useSuspense(<NotFound404Page />),
    },
  ];

  useEffect(() => {
    const userSvc = new UserService();
    userSvc.getUserProfile().then(
      (response) => {
        setProfiles(response?.data?.profiles);
        setUser(response?.data?.userId);
        setDataConsentScreenShown(
          response?.data?.dataTrackingScreenShown || true
        );
        if (response?.data?.dataTrackingConsent === 'accepted') {
          if (userID && partnerID) initializePendo(userID, partnerID);
          if (userID)
            initializeClarity(userID, 'MAXHOME-WEB', 'Max Landing Page');
        }
      },
      (error) => {
        console.error('Error fetching user profile: ', error);
      }
    );
  }, []);

  return (
    <>
      <RecoilRoot>
        <GlobalProvider>
          <FlagsmithProvider
            options={{
              environmentID: environment?.featureFlag?.API_BASE_URL,
            }}
            flagsmith={flagsmith}
          >
            <ThemeProvider theme={theme}>
              <ConfigProvider theme={{ token: { colorPrimary: '#2F78EB' } }}>
                <ToastContainer />
                {/* Uncomment this div to enable firebase ui auth */}
                {/* <div id="firebaseui-auth-container"></div> */}
                <Router>
                  <Auth0WithNavigate>
                    <Routes>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route?.path}
                          element={route.element}
                        />
                      ))}
                    </Routes>
                  </Auth0WithNavigate>
                </Router>
                {!getDataConsentScreenShown() && firebaseAuth?.currentUser ? (
                  <CookieBanner privacyPolicyUrl="/privacy-policy" />
                ) : null}
              </ConfigProvider>
            </ThemeProvider>
          </FlagsmithProvider>
        </GlobalProvider>
      </RecoilRoot>
    </>
  );
}

export { firebaseAuth };
export default App;
