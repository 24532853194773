// const prefix = '/v2';
const prefix = '';

export const AppURLs = {
  // Landing Page
  landingPage: () => '/',

  // Auth
  agentLogin: () => prefix + '/agent/login',
  agentLoginSessionExpired: () => prefix + '/agent/login?sessionExpired=true',

  complianceLogin: () => prefix + '/compliance/login',
  complianceLoginSessionExpired: () =>
    prefix + '/compliance/login?sessionExpired=true',

  // Agent Onboarding
  agentOnboardingAddEmail: (partnerID: string = '') =>
    prefix + `/agent/onboarding/add-email?partnerID=${partnerID}`,
  // Agent Dashboard Main
  agentDashboardHome: () => prefix + '/agent/dashboard/home',
  agentDashboardTransactions: () => prefix + '/agent/dashboard/transactions',
  agentDashboardTasks: () => prefix + '/agent/dashboard/tasks',
  agentDashboardAddresses: () => prefix + '/agent/dashboard/addresses',
  agentDashboardContacts: () => prefix + '/agent/dashboard/contacts',
  agentDashboardClients: () => prefix + '/agent/dashboard/clients',
  templates: () => prefix + '/agent/dashboard/templates',
  templateDocument: (documentId: string) =>
    prefix + `/agent/dashboard/templates/${documentId}`,
  agentDashboardTransaction: (
    transactionID: string,
    triggerUpload: boolean = false
  ) =>
    prefix +
    `/agent/dashboard/transactions/detail?transactionID=${transactionID}&${triggerUpload ? 'triggerUpload=true' : ''}`,
  agentDashboardTransactionVerifyDocument: (
    transactionID: string,
    documentID: string
  ) =>
    prefix +
    `/agent/dashboard/transactions/verify?transactionID=${transactionID}&documentID=${documentID}`,
  agentDashboardTransactionRequestSignature: (transactionID: string) =>
    prefix +
    `/agent/dashboard/transactions/request-signature?transactionID=${transactionID}`,
  // Agent Dasboard Property Task
  agentDashboardPropertyBase: () => prefix + `/agent/dashboard/property`,
  agentDashboardPropertyDetails: (token: string) =>
    prefix + `/agent/dashboard/property/${token}`,
  agentDashboardPropertyTasks: (token: string, selectedTaskID: string) =>
    prefix +
    `/agent/dashboard/property/${token}/tasks?selectedTaskID=${selectedTaskID}`,

  // Agent Dashboard Buyer Agency Agreement
  agentDashboardBuyerAgencyAgreement: () =>
    prefix + '/agent/dashboard/buyer-agency-agreement',

  // Compliance Dashboard
  complianceDashboardTransactions: () =>
    prefix + '/compliance/dashboard/transactions',
  complianceDashboardComplianceTransactions: () =>
    prefix + '/compliance/dashboard/compliance-transactions',
  complianceDashboardChecks: () => prefix + '/compliance/dashboard/checks',
  complianceDashboardTasks: () => prefix + '/compliance/dashboard/tasks',
  complianceDashbaordWorkAllocation: () =>
    prefix + '/compliance/dashboard/work-allocation',
  // complianceDashboardTransaction: (transactionID: string) =>
  //   prefix +
  //   `/compliance/dashboard/transactions/documents?transactionID=${transactionID}`,
  complianceDashboardTransaction: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/detail?transactionID=${transactionID}`,

  complianceDashboardTransactionContacts: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/contacts?transactionID=${transactionID}`,
  complianceDashboardTransactionFacts: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/facts?transactionID=${transactionID}`,
  complianceDashboardTransactionTasks: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/tasks?transactionID=${transactionID}`,
  complianceDashboardTransactionVerifyDocument: (
    transactionID: string,
    documentID: string
  ) =>
    prefix +
    `/compliance/dashboard/transactions/verify?transactionID=${transactionID}&documentID=${documentID}`,
  complianceDashboardTransactionRequestSignature: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/request-signature?transactionID=${transactionID}`,
  complianceDashboardValidation: (checkID: string) =>
    prefix + `/compliance/dashboard/validation?checkID=${checkID}`,

  // Office Admin
  officeAdminChecks: () => prefix + '/office-admin/dashboard/checks',
  officeAdminDocuments: (transactionID: string) =>
    prefix + `/office-admin/dashboard/documents?transactionID=${transactionID}`,
  officeAdminDashboardValidation: (checkID: string) =>
    prefix + `/office-admin/dashboard/validation?checkID=${checkID}`,

  // Shared
  privacyPolicy: () => prefix + '/privacy-policy',
};
